.action_container {
    position    : fixed;
    right       : 16px;
    bottom      : 100px;
    // top      : 50%;
    // transform: translateY(-50%);

    .action_item {
        width        : 44px;
        height       : 44px;
        border-radius: 50%;
        background   : #ffffff;
        border       : 1px solid #e6e6e6;
        box-shadow   : 0px 6px 8px 0px rgba(70, 65, 88, 0.10);
        cursor       : pointer;



        &>i {
            display: block;
            width  : 100%;
            height : 100%;

            background-size: 100% 100%;

        }

        .action_service {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAmVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////VHQRUAAAAMnRSTlMA2/nu/KjzgDQtJBLTit+7l2YfGeO2eFFJOQvHm28P560Iy7+iWrGfhXNhXT/pzJKOhDqf8bwAAAJ7SURBVGje7MG3DQAgDAAwBFuGlP+f5QnEZHsBAADAIzFdefY+WT2xPrm01cmOglAQheEDCM0gDuCsQeNsjMY+7/9wXVdi6O443ITiW9buLP5UlA74xyCN0Lywyye6IZoV+3zBj9Ggo8tSa9P76k8m/a/epsWSe0RTiiHvAn9X/Lru/IB3wwKNiJ1yWZrgnyR1aTgxGjD1aBwyPJEdaHhTqMsuFMEYL4wDiksGZbM2hRO9yduhaM+ga0+xTvBGsqbYQ9WJYpDjrXxAcYKixCMZhPggDEh6CfTcKHb4aEdxg5rMZLqBhY0JPYOWLUUICyHFFkoKM9iHFd9MLqCjTxHBSkTRh44hSQeWHJJD6DCRpLCUmvCgIqSYwtKUIoSGM0kX1lySZ2g4kuzAWofkNzQsSM5hbU5yAQ0mzRWsrUz0ajX1YK2n1FO8omgtcljJFy2KVYyaIo8lJ4eF3GHJi1DLbMmHKyxc+bCcoY4RK2N8NGZlhDrmrGxt/mdljjp+2q+jnYRhKIzj31hBYYUJTAbDZY4LjbiE5Hv/h9ORhTO92Uxtw8X5vcC/7UVPG1OcMOhEEcMFe6JRo0loWMMavo+wobAYZCkMXKwoUgxKKVaOzx6x+9tMeYGLzUx2UGBQISc028DJmR3TYITGsHOGo7xiKzpglEPEVpXDWVK+ptN1jZFqS/ItQXgZyRJByU9rj/AuJGOEt5jI8z+oZ/nTBrXktyl8SsqHzm7+88qMt/CojnhjHnFVTOXO8qZhzxKtz0xmiT979lyA7YeVRfT4DR/freHVpIRnEu6zcwzwEs7KGt4t+Is5rmuEUMa3ZGXTpzyBUkoppZRSSqn/9gXQTbJiAUFJgwAAAABJRU5ErkJggg==);
        }

        .action_code {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4BAMAAADLSivhAAAALVBMVEUAAAAAAFIAAFMAAFIAAFMAAFIAAFQAAFMAAFUAAFMAAFMAAFMAAFMAAFUAAFP6i17xAAAADnRSTlMATsR22DuJ4Rjyte2lJBgj+J4AAACuSURBVFjD7dUxCgIxEIXhUQlY6g1kCsVOCCJY5wrbWa+dhV7AM3gF7+EZvITYzRmcZiKk24UsG/K+Zrb5YQlDQgAAAACQkGgxkQhxtvjBvLH4yrztFK+IZhbviKaIc8Y3748WP70/FLJh1cXL6D7/f9PYJb/9JnI6eh2Y7TbiLHF6Gazb9tzzGjJDxy6E8BL56ugek7qIfHSUENsT65h53zQnHSVsWHUxAAAAQD1+0nVULr0LwPoAAAAASUVORK5CYII=);
        }

        .action_dingyue {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAARVBMVEUAAAAAAFMAAFMAAFIAAFEAAFMAAFQAAFMAAFMAAFMAAFQAAFUAAFMAAFMAAFMAAFMAAFMAAFMAAFIAAFMAAFMAAFEAAFPaWmGSAAAAFnRSTlMAtvQcQ92xk0k6Iw356ufWyIuCb2oWqiHVwAAAAL5JREFUaN7t0ssOgyAQQNHB+kDw/eD/P7ULYzRGW5pMXZh7thBuZoIAAAAAAABgk5omfNWYVJQNIdKgPG+IpjuzCdGMaKpDtFo0hR8QVgublxy8zC3hrbuVnx0+W/WzPxdhwoQJEyZMmDBhwoQJEyZ8FU4KO8+2SG4OZ6NfDvyY3Rg2Vnas+WO42u/YyYHbb7wSTd36bDt5OeGndr3RiaZyGSlP5VKaLwspRbecJ1nv5CPXZ0leCgAAAAAAwJU3P66oqYpXvC0AAAAASUVORK5CYII=);
        }

        .action_backup {
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAWlBMVEUAAAAAAFMAAFUAAFMAAFMAAFMAAFMAAFIAAFMAAFMAAFMAAFMAAFMAAFMAAFMAAFMAAFUAAFMAAFMAAFQAAFMAAFQAAFMAAFQAAFIAAFMAAFMAAFMAAFMAAFPQu6bcAAAAHXRSTlMAlBGNcdT0Je3pLuTf2ngpCsU/OzQfGhaiiteqX2T07pEAAADdSURBVGje7c/BbsIwEEXRgTY2gSQkQFtoO///m0hIYbwAglFsC+me5dtcPQEAAAAAAIixq4a1FLBzqqusZeuWKO+dXtRbycS6JcoHp1e1l2wOKw1sMpStW6K8HrtW/pIMrGuapGXrlihva72p+ZZQ8q5pk5a9dbOW/UYf+P0Rk6FrqkTlvtEJgySx10lpLveDTjhKIsvRQgPduPaS3IcGPuUuwoQJEyZM+C7C7xvuNPAv+Sz/qqtjLwCe5E+LSCcvc2g1Witz0BcQfonTaE7m0LnobicAAAAAAAA3nAH1q2hVzVM0+AAAAABJRU5ErkJggg==);
        }

        &:not(:last-child) {
            margin-bottom: 14px;
        }


        &:hover {
            background-color: #F6F8FF;

            .action_service {
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAMAAAAOusbgAAAAhFBMVEUAAAAAqrIAqrIAq7MAqrIAqrIAqrIAqrMAqbEAqrMAqrIAqrIAqrIAqrIAqbAAqrIAqrIAq7IAqrIAqrIAqrIAqrMAqrMAqrIAqrIAqbIAqrIAqrIAqrIArLQAqrAAqrIAqrIAq7IAq7IAqrIAqrIAqrIAqrIAr68AqrMAq7MAqbMAqrIbx+aPAAAAK3RSTlMA3PsQ4tbwqIC0LSScUQr1l4tm6My6WzkzG+3HeB8Vv62FckmikG4EYT81r8QuPQAAAl5JREFUaN7t2NlyqkAQBuCfQUDZwiIKGtdETdLv/36nGjliThanagbqXPR3OdVVTQ90DwAhhBBCCCGEEMKSqMgqZ6XUyqmyIsJIwnxCn0zyEMOrK/pGVWNYUUA/CCIM6KDoSu2yaZEkxTTb3ZYOGIo7o9ZTsHDvVhfBE7VmLgYRede0ufvlivJrai/CAJY+sbjBN5qYmL+EdZuyLTfBD5K26HIDy9J2n53wl/Z22t1OYVf7XM23+MV2zjEzWHVsJ1SDXzXtRDvCItfn+1vjgZrvs+/CnhOXssBDC447wZoNT6cdNOx4hG1gyysX8gENHxz5CktcLjiAloBLdmFHwWWE0BJybGGxhx1ocuz18oV7KYemnDvqAhtq3r0lNC05uoYN3JwK2lTb8jYceFpCG8/NA2zgo3YNbWs+tGEDt+Ye2vbc9Na6KYO2zFI/RVwBqXgLLdtYcfw+gqFwRVdOAw2N04WvQhhJS/prDg3zW3iZwsQz9RI8lFDvGSbW1HvVOT97a5jwqTfX32nmwwTdcbSOpp4klsSS+P9IrKjn4SGPegomJtSr8FBFvQlMxF+mvvaZEsPExu8rcPGQ2++Qv4GRN+qod2h4V9R5g6GkJOYtoWXpESsTGHOP+11QpPp/aQx+tRk3whnjaz/NXzC+E79iXjC6SLWv/+Obdd+0Y8s5b4Ahbc/TznP4eWT6DQaUOnSj6m4t6GbWkN7pzgFsMSE2xaBe6M4J2B49auXQZp54HXuKWuoMbeaJe14IDdYTT84pBhfRP9S6uGAMx9UtZTmvssSFEEIIIYQQQgjb/gC7WpqdCDiIRAAAAABJRU5ErkJggg==);
            }
        }

    }

    .active_item {
        background-color: #000053;
    }


}

.action_code_box {
    display       : flex;
    flex-direction: column;
    align-items   : center;

    .action_code {
        width        : 129px;
        height       : 129px;
        margin-bottom: 8px;

        &>img {
            width : 100%;
            height: 100%;
        }
    }

    .action_code_desc {
        font-size  : 12px;
        font-weight: 400;
        text-align : center;
        color      : #151515;
    }
}