.researchpop_container {
    padding: 8px;

    .researchpop_list {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &_item {
            &:hover {
                background-color: #F6F8FF;
            }

            cursor       : pointer;
            width        : 187px;
            height       : 78px;
            background   : #ffffff;
            border       : 1px solid #e6e6ec;
            border-radius: 4px;

            display     : flex;
            align-items : center;
            box-sizing  : border-box;
            padding-left: 18px;

            font-size  : 16px;
            font-weight: 500;
            color      : #151515;

            &:not(:last-child) {
                margin-right: 16px;
            }

            &>i {
                width          : 28px;
                height         : 28px;
                background-size: 100% 100%;
                margin-right   : 14px;
            }

        }
    }
}