.about_us_modal {
  width: 470px;
  height: 284px;
  // background: #ffffff;
  // border-radius: 0px 0px 4px 4px;
  // box-shadow: 0px 5px 20px 0px rgba(74, 76, 132, 0.20);
  padding: 8px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  div {
    box-sizing: border-box;
  }

  .about_us_modal_left {
    width: 275px;
    height: 268px;
    background: url('//files.data100.com/web/about-us-modal-bg.png') no-repeat, linear-gradient(135deg, #eaeaff, #effdff);
    background-size: contain;
    background-position: right;
    border-radius: 4px;
    padding: 10px 16px;
    

    .about_us_modal_title {
      background: url('//files.data100.com/web/about-us-modal-title.png') no-repeat;
      background-size: contain;
      padding-left: 30px;
      margin: 8px 10px;
      margin-bottom: 20px;
    }

    .about_us_modal_text {
      height: 36px;
      border-radius: 4px;
      margin-bottom: 12px;
      padding-left: 52px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: #151515;
      cursor: pointer;

      img {
        width: 16px;
        object-fit: contain;
        margin-right: 8px;
      }
    }

    :nth-child(2) {
      background: url('//files.data100.com/web/about-us-modal-pinpai.png') no-repeat;
    }

    :nth-child(2):hover {
      background: url('//files.data100.com/web/about-us-modal-pinpai-hover.png') no-repeat, #FFFFFF;
    }

    :nth-child(3) {
      background: url('//files.data100.com/web/about-us-modal-jiazhiguan.png') no-repeat;
    }

    :nth-child(3):hover {
      background: url('//files.data100.com/web/about-us-modal-jiazhiguan-hover.png') no-repeat, #FFFFFF;
    }

    :nth-child(4) {
      background: url('//files.data100.com/web/about-us-modal-gaoguan.png') no-repeat;
    }

    :nth-child(4):hover {
      background: url('//files.data100.com/web/about-us-modal-gaoguan-hover.png') no-repeat, #FFFFFF;
    }

    :nth-child(5) {
      background: url('//files.data100.com/web/about-us-modal-zizhi.png') no-repeat;
    }

    :nth-child(5):hover {
      background: url('//files.data100.com/web/about-us-modal-zizhi-hover.png') no-repeat, #FFFFFF;
    }

    .about_us_modal_text {
      background-size: 16px;
      background-position: 30px;
    }

    .about_us_modal_text:hover {
      background-size: 16px;
      background-position: 30px;
    }
  }

  .about_us_modal_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .about_us_modal_card {
      width: 164px;
      height: 78px;
      background: #ffffff;
      border: 1px solid #e6e6ec;
      border-radius: 4px;
      padding: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 28px;
        object-fit: contain;
        margin-right: 16px;
      }
    }

    .about_us_modal_card:hover {
      background: #F6F8FF;
      border-color: #F6F8FF;
    }
  }
}