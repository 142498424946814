body {
  margin     : 0;
  font-family: PingFangSC-Regular, Source Han Sans CN, Source Han Sans CN-Regular, 'Microsoft YaHei', sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}