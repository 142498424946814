@primary-color: #000053;

// 页面块公共样式
.common_section {
    width          : 100%;
    display        : flex;
    justify-content: center;
    min-width      : 1320px;

    .common_container {
        width: 1200px;
    }
}

.common_flex_column {
    display       : flex;
    width         : 1200px;
    flex-direction: column;
    align-items   : center;
}

.common_title {
    height     : 60px;
    font-size  : 40px;
    display    : flex;
    align-items: center;
    color      : #151515;
    font-weight: 500;
    font-family: PingFangSC-Regular,Source Han Sans CN,Source Han Sans CN-Regular,Microsoft YaHei,sans-serif
    //font-family: Source Han Sans CN, Source Han Sans CN-Medium;

}

.common_divider {
    width     : 106px;
    height    : 4px;
    background: @primary-color;
    margin-top: 8px;
}

.common_second_title {
    margin-top: 16px;
    font-size : 16px;
    color     : #444444;
}

.common_desc {
    margin-top: 16px;

    width     : 600px;
    font-size : 12px;
    color     : #999999;
    text-align: center;
}



// 案例实践
.common_case_list {
    width          : 100%;
    display        : flex;
    justify-content: center;

    .common_case_item {
        width        : 380px;
        height       : 390px;
        border-radius: 8px;
        position     : relative;

        &:not(:last-child) {
            margin-right: 30px;
        }

        .common_case_item_header {
            width          : 100%;
            height         : 140px;
            display        : flex;
            align-items    : center;
            justify-content: center;

            &>img {
                width : 100%;
                height: 100%;
            }
        }

        .common_case_item_content {
            position        : absolute;
            left            : 0;
            right           : 0;
            bottom          : 0;
            top             : 130px;
            background-color: #ffffff;
            border          : 1px solid #e6e6ec;
            border-radius   : 8px;

            box-sizing: border-box;
            padding   : 28px;
        }

        &_title {
            font-size  : 22px;
            font-weight: 400;
            color      : #131415;
        }

        &_divider {
            width        : 48px;
            height       : 2px;
            background   : #18191a;
            margin-top   : 16px;
            margin-bottom: 28px;

        }

        &_desc {
            font-size  : 14px;
            font-weight: 400;
            color      : #666666;
            line-height: 20px;
        }
    }

}

// 公共banner样式
.common_banner {
    height         : 400px;
    display        : flex;
    flex-direction : column;
    justify-content: center;

}

.common_banner_title {
    font-size  : 60px;
    font-weight: 400;
    color      : #ffffff;
}

.common_banner_divider {
    width           : 100px;
    height          : 8px;
    margin-top      : 36px;
    background-color: #00AAB2;
}

.common_banner_desc {
    margin-top: 36px;
    font-size : 22px;
    color     : #fff;
}